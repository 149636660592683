<template>
    <div>
        <div class="table_box">
            <el-row class="sliderbar-nav">
                <el-col :span="18">
                    <div v-for="item in typeList" :key="item.id" @click="switchMenu(item)"
                         :class="typeListId === item.id?'breadcrumb-nav-item active':'breadcrumb-nav-item'">{{item.name}}
                    </div>
                </el-col>
                <el-col :span="6">
                </el-col>
            </el-row>
            <el-row class="eltable">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="userVersion" :label="$t('m.template.userVersion')">
                    </el-table-column>
                    <el-table-column prop="userDesc" :label="$t('m.template.userDesc')">
                    </el-table-column>
                    <el-table-column prop="templateType" :label="$t('m.template.templateType')">
                    </el-table-column>
                    <el-table-column prop="createTime" :label="$t('m.template.createTime')">
                    </el-table-column>
                    <el-table-column :label="$t('m.pubilc.caozuo')" align="center">
                        <el-button v-if="typeListId==1" type="text" size="mini" @click="releaseWeChatCode(scope.row)">{{$t('m.template.fabu')}}</el-button>
                        <el-button v-if="typeListId==0" type="text" size="mini" @click="addNormalTemplate(scope.row)">{{$t('m.template.fabuzhengshi')}}</el-button>
                    </el-table-column>
                </el-table>
            </el-row>
        </div>
    </div>
</template>

<script>
    import {getNormalTemplateList,
        getTemplateDraftList,
        releaseWeChatCodeTemplate,
        addToTemplate} from "../../../request/api/client_list";
    export default {
        name: "codeTemplate",
        data() {
            return {
                typeList: [{
                    id:1,
                    name:'正式模板'
                },{
                    id:0,
                    name:'草稿模板'
                }],
                typeListId:1,
                tableData: [],
            }
        },
        created() {
            this.switchMenu(this.typeList[0])
        },
        methods:{
            getNormalTemplate() {
                getNormalTemplateList().then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        this.tableData = res.data
                    }
                })
            },
            getTemplateDraft() {
                getTemplateDraftList().then(res => {
                    if (res.code == 200) {
                        console.log(res)
                        this.tableData = res.data
                    }
                })
            },
            switchMenu(item) {
                this.typeListId = item.id
                if(item.id==1){
                    this.getNormalTemplate()
                }else{
                    this.getTemplateDraft()
                }
            },
            //发布新的微信代码模板
            releaseWeChatCode(row){
                releaseWeChatCodeTemplate({
                    templateId:row.templateId
                }).then(res=>{
                    if (res.code == 200) {
                        this.getNormalTemplate()
                    }
                })
            },
            //发布到正式模板
            addNormalTemplate(row){
                this.$confirm('确定发布到正式模板?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    addToTemplate({
                        draftId:row.draftId,
                        templateType:0
                    }).then(res=>{
                        if (res.code == 200) {
                            this.getTemplateDraft()
                        }
                    })
                })

            },
        },
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @import "../../../assets/style/table";
    .table_box {
        padding: 15px;
        margin: auto;
        background: #fff;
    }
    .breadcrumb-nav-item {
        vertical-align: middle;
        text-decoration: none;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        min-width: 0;
        border: none;
        background: transparent;
        font-size: 14px;
        color: #666;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
    }

    .breadcrumb-nav-item.active:after {
        display: block;
        color: #333;
        border-bottom: 2px solid @color-primary;
        content: "";
        margin: 0;
    }
    .sliderbar-nav {
        border-bottom: 1px solid #ebedf0;
        margin-bottom: 10px;
        margin-top: -13px;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .eltable{
        margin-top: 18px;
    }

</style>